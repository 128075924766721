


































































import { Vue, Provide, Component } from "vue-property-decorator";
import serve from "@/serve/login";
import { Toast } from "vant";
import md5 from "js-md5";
import Cookies from 'js-cookie'
@Component({})
export default class Login extends Vue {
  @Provide() tabIndex = 0;
  @Provide() saveWords = true;
  @Provide() username = "";
  @Provide() mobile = "";
  @Provide() password = "";
  @Provide() code = "";
  @Provide() sendLoading = false;
  @Provide() time = 60;

  created() {
    if (localStorage.getItem("user")) {
      const str = localStorage.getItem("user");
      if (typeof str === "string") {
        const user = JSON.parse(str);
        this.username = user.account;
        this.password = user.password;
      }
    }
  }
  private tabChange(val: number): void {
    this.tabIndex = val;
  }
  private forget(): void {
    this.$router.push("/forget");
  }
  async sendSms() {
    if (this.mobile) {
      const reg = /^1[3-9]\d{9}$/;
      if (reg.test(this.mobile)) {
        const res = await serve.getCode(this.mobile)
        if(res.data.code === 0){
          Toast('验证码已发送，请注意查收')
          this.sendLoading = true;
          this.cutTime();
        }
      } else {
        Toast("手机号格式错误");
      }
    } else {
      Toast("请输入手机号");
    }
  }
  private signIn(): void {
    this.$router.push("/signIn");
  }
  private cutTime(): void {
    const timer = setInterval(() => {
      this.time--;
      if (this.time == 0) {
        clearInterval(timer);
        this.time = 60;
        this.sendLoading = false;
      }
    }, 1000);
  }

  async login() {
    if (this.tabIndex === 0) {
      const data = {
        "client_id": "client",
        "grant_type": "password",
        scope: "all",
        username: this.username,
        password: md5(this.password),
        "client_secret": "123456",
      };
      // alert("正在请求");
      const res = await serve.login(data);
      if (res.data.code === 0) {
        Cookies.set('token', res.data.data.token_type + res.data.data.access_token, {expires: 7})
        
        
        // alert("请求成功");
        this.$router.push("/home");
        if (this.saveWords) {
          const user = {
            account: this.username,
            password: this.password,
          };
          localStorage.setItem("user", JSON.stringify(user));
        } else {
          localStorage.removeItem("user");
        }
      } else {
        Toast(res.data.message);
      }
    }else {
      const data = {
        mobile:this.mobile,
        code:this.code,
        "clientId":'client',
        "client_secret":123456,
        "grant_type":'password'
      }
      const res = await serve.mobileLogin(data)
      if(res.data.code ===0){
        const data = res.data.data.tokenType + res.data.data.value
        sessionStorage.setItem('token',data)
        this.$router.push('/home')
      }
    }
  }
}
